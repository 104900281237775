import React, { useEffect, useRef, useState } from 'react'
import styles from './home.module.scss'
import DriveBanner1 from '../../assets/webp/drive_header.webp'
import DriveBannerMobile from '.././../assets/webp/drive_mobile_banner.webp'
import IphoneDesktop from '../../assets/webp/Iphone_desktop.webp'
import IphoneMobile from '../../assets/webp/Iphone_Mobile.webp'
import { ReactComponent as WindowsLogo } from '../../assets/svg/windows_logo.svg'
import { ReactComponent as MacLogo } from '../../assets/svg/mac_logo.svg'
import { ReactComponent as LinuxLogo } from '../../assets/svg/linux_logo.svg'
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'

import { ReactComponent as DriveFeature1 } from '../../assets/svg/driveFeature1.svg'
import { ReactComponent as DriveFeature2 } from '../../assets/svg/driveFeature2.svg'
import { ReactComponent as DriveFeature3 } from '../../assets/svg/driveFeature3.svg'
import { ReactComponent as DriveFeature4 } from '../../assets/svg/driveFeature4.svg'
import { ReactComponent as DriveFeature5 } from '../../assets/svg/driveFeature5.svg'
import { ReactComponent as DriveFeature6 } from '../../assets/svg/driveFeature6.svg'
import { ReactComponent as DriveFeature7 } from '../../assets/svg/driveFeature7.svg'
import { ReactComponent as DriveFeature8 } from '../../assets/svg/driveFeature8.svg'
import { ReactComponent as DriveFeature9 } from '../../assets/svg/driveFeature9.svg'
import { ReactComponent as DriveFeature10 } from '../../assets/svg/driveFeature10.svg'

import Drive_Ilustration1_1 from '../../assets/webp/drive_illustration_0_1.webp'
import Drive_Ilustration1_2 from '../../assets/webp/drive_illustration_0_2.webp'
import Drive_Ilustration1_3 from '../../assets/webp/drive_illustration_0_3.webp'

import Drive_Ilustration1 from '../../assets/webp/drive_illustration1.webp'
import Drive_Ilustration2 from '../../assets/webp/drive_illustration2.webp'
import Drive_Ilustration3 from '../../assets/webp/drive_illustration3.webp'
import Drive_Ilustration3_mobile from '../../assets/webp/drive_illustration3_mobile.webp'
import Drive_Ilustration5 from '../../assets/webp/drive_illustration5.webp'
import Drive_Ilustration5_mobile from '../../assets/webp/drive_illustration5_mobile.webp'
import Drive_Ilustration4_2 from '../../assets/webp/drive_illustration_4_2.webp'
import Drive_Ilustration4_2_Mobile from '../../assets/webp/drive_illustration_4_2_mobile.webp'
import Drive_Ilustration4_3 from '../../assets/webp/drive_illustration_4.3.webp'
import Drive_Ilustration4 from '../../assets/webp/recovery_img1.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_mobile.webp'
import Macbook_desktop from '../../assets/webp/macbook_desktop.webp'
import Poster from '../../assets/webp/driveVideoPoster.webp'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import { Link } from 'react-router-dom';
import { useDownloadHook } from '../../_shared/hooks/download.hook';
import { useDeviceStore } from '../../stateManager/DeviceStore';
import { useOsStore } from '../../stateManager/OsStore';
import { ReactComponent as BlueCircle } from '../../assets/svg/blueCircle.svg'
import { ReactComponent as GrayCircle } from '../../assets/svg/grayCircle.svg'
import { ReactComponent as GoogleDrive } from '../../assets/svg/gooleDriveIcon.svg'
import { ReactComponent as OneDrive } from '../../assets/svg/oneDriveLogo.svg'
import MobilePoster from '../../assets/webp/video_poster_mobile.webp'
import DesktopPoster from '../../assets/webp/video_poster_desktop.webp'

// LAZY LOADING
import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));
const { PromotionalBanner } = lazily(() => import('../../_shared/components/PromotionalBanner/promotionalBanner.component'));


function Home() {

    const { device } = useDeviceStore();
    const { os } = useOsStore();
    const { handleBuildDownload } = useDownloadHook();
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (videoRef.current) videoRef.current.playbackRate = .8;
    }, [])


    return (
        <div className={styles.homeContainer}>

            <FloaterDownload title='To protect your files' buttonText='Download' mode={`${THEME.DARK}`} />

            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        Store, Search, Access, Sync, and Share Files with 100% Privacy
                    </div>

                </div>
                <div className={styles.headerImg}>
                    {/* <img src={device !== DEVICE.MOBILE ? `${DriveBanner1}` : `${DriveBannerMobile}`} alt='Zunu mail Header' /> */}
                    <video autoPlay muted loop playsInline preload="auto" className={styles.video} 
                    poster={device == DEVICE.MOBILE ? MobilePoster : DesktopPoster} 
                    ref={videoRef}
                    >
                        <source src={`assets/webm/drive_introduction_mobile.webm`} type={`video/webm`} media="(max-width:730px)" />
                        <source src={`assets/mp4/drive_introduction_mobile.mp4`} type={`video/mp4`} media="(max-width:730px)" />
                        <source src={`assets/webm/drive_introduction_desktop.webm`} type={`video/webm`} />
                        <source src={`assets/mp4/drive_introduction_desktop.mp4`} type={`video/mp4`} />

                        Your browser does not support the video tag.
                    </video>
                </div>
                {device !== DEVICE.MOBILE &&
                    <div>
                    </div>
                }
            </div>

            <PromotionalBanner />

            <div className={styles.driveContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadLeftContainer}>
                        <p className={styles.downloadHeader}>Keep Files Private, All the Time, Everywhere. Download Zunu Drive Now.</p>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.download_Os_container}>
                                <div className={styles.downloadBtnContainer}>
                                    <button className={styles.downloadBtn} id="banner1Download" onClick={() => { handleBuildDownload(os) }}>Download Now</button>
                                </div>

                                <div className={styles.osLogoContainer}>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }} id="banner1Windows"><WindowsLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }} id="banner1Mac"><MacLogo className={styles.osLogos} /></Link>
                                    {/* <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }} id="banner1Linux"><LinuxLogo className={styles.osLogos} /></Link> */}
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }} id="banner1AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }} id="banner1PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.downloadImgContainer}>
                        <img src={IphoneDesktop} />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.osLogoContainer}>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }} id="banner1Windows"><WindowsLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }} id="banner1Mac"><MacLogo className={styles.osLogos} /></Link>
                            {/* <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }} id="banner1Linux"><LinuxLogo className={styles.osLogos} /></Link> */}
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }} id="banner1AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }} id="banner1PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    }
                </div>

            </div>

            <div className={`${styles.driveFeatures_Container}  ${styles.driveContentContainer}`}>
                <div className={styles.driveFeatures_header}>
                    Complete, Consistent, and<br></br> Continuous Privacy for Files with Zunu Drive.
                </div>
                <div className={styles.drive_Features_content}>
                    {/* <div className={styles.featureContainer}> */}
                    <div className={styles.drive_feature}>
                        <DriveFeature1 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Files Protection during Device Theft
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature6 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Share Files with Control
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature2 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Files on the Cloud are Invisible to the Cloud
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature7 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Encrypted Sync for Seamless Privacy
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature3 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Files Secure Even if the Cloud is Breached
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature8 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Instant Passwordless Sign-in Across All Devices
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature4 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Privatize Your Existing Clouds—No Migration Needed
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature9 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Dark Web-Proof File Privacy
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature5 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Unified Access to all Clouds
                        </p>
                    </div>
                    <div className={styles.drive_feature}>
                        <DriveFeature10 className={styles.featureIcons} />
                        <p className={styles.featureDescription}>
                            Files Protected from 3rd Party Apps with Storage Access
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${styles.simplifiedPrivacyContainer} ${styles.driveContentContainer}`}>
                <div className={styles.simplifiedPrivacyChild}>
                    <div className={styles.simplifiedHeader}>
                        <div className={styles.driveFeatures_header}>
                            Unify Your Clouds:
                        </div>
                        <div className={`${styles.driveFeatures_header} ${styles.driveFeature1Header}`}>Manage all of them in One Place.</div>
                    </div>
                    <div className={styles.privacyContainer}>
                        <div className={styles.privacyContent}>
                            <div className={styles.privacyContentChild}>
                                <div className={styles.addCloudContainer}>
                                    <div className={styles.cloudHeaderContainer}>
                                        <p className={styles.addCLoudHeader}>Add Cloud</p>
                                        <p className={styles.addCloudSubHeader}>
                                            Dear User, Let's make your cloud private. Please add a could.
                                        </p>
                                    </div>
                                    <div className={styles.driveContainer}>
                                        <div className={styles.googleDrive}>
                                            <div className={styles.driveLogoContainer}>
                                                <BlueCircle className={styles.selectedCircle} />
                                                <GoogleDrive className={styles.DriveIcons} />
                                            </div>
                                            <p className={styles.driveHeader}> Google Drive</p>
                                        </div>
                                        <div className={`${styles.oneDrive} ${styles.googleDrive}`}>
                                            <div className={styles.driveLogoContainer}>
                                                <GrayCircle className={styles.selectedCircle} />
                                                <OneDrive className={styles.DriveIcons} />
                                            </div>
                                            <p className={`${styles.driveHeader} ${styles.oneDriveHeader}`}> OneDrive</p>
                                        </div>
                                    </div>
                                    <hr className={styles.border}></hr>
                                    <div className={styles.cloudAccountBtnContainer}>
                                        <div className={styles.cloudNameContainer}>
                                            <p className={styles.enterCloudName}>Cloud Name</p>
                                            <div className={styles.cloudInputContainer}>
                                                <span>I</span>
                                                Enter your cloud name
                                            </div>
                                        </div>
                                        <div className={styles.cloudBtnContainer}>
                                            <button className={styles.cancelButton}> Cancel</button>
                                            <button className={styles.addCloudBtn}>Add Cloud</button>
                                        </div>
                                    </div>
                                </div>
                                <p className={styles.cloudText}>Skip the hassle of creating one more account for Privacy.</p>
                            </div>
                        </div>
                        <div className={styles.privacyContent}>
                            <img src={Drive_Ilustration1_2} />
                            <p className={styles.cloudText}>Make Existing Accounts Private.</p>
                        </div>

                        <div className={styles.privacyContent}>
                            <img src={Drive_Ilustration1_3} alt='drive' />
                            <p className={styles.cloudText}>Eliminate Multiple Passwords</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.illustrationContainer} ${styles.driveContentContainer}`}>
                <div className={styles.illustrationText}>
                    <p className={styles.illustrationHeader}>Encrypted Sync: Files Hidden From Cloud, in Cloud.</p>
                </div>
                <div className={styles.encryptedSyncImg}>
                    <img src={Drive_Ilustration1} alt='drive illustration' />
                </div>
            </div>

            <div className={`${styles.drivePrivacyContainer} ${styles.driveContentContainer}`}>
                <div className={styles.driveFeatures_header}>
                    Share with more control.
                </div>
                <div className={styles.driveImgContainer}>
                    <div className={styles.driveShareImg}>
                        <img src={Drive_Ilustration2} alt='illustration' />
                    </div>
                    <div className={styles.driveShareImg}>
                        <img src={device == DEVICE.MOBILE ? `${Drive_Ilustration3_mobile}` : `${Drive_Ilustration3}`} alt='illustration' />
                    </div>
                </div>
            </div>

            <div className={`${styles.drivePrivacyContainer} ${styles.driveContentContainer}`}>
                <div className={styles.driveFeatures_header}>
                    One Click Recovery.
                </div>
                <div className={styles.recoveryImgContainer}>
                    <div className={styles.recoveryImg}>
                        <img src={Drive_Ilustration4} alt='illustration' />
                    </div>
                    <div className={styles.recoveryChildContainer}>
                        <div className={styles.recoveryDriveImg}>
                            <img src={device == DEVICE.MOBILE ? `${Drive_Ilustration4_2_Mobile}` : `${Drive_Ilustration4_2}`} alt='illustration' />
                        </div>
                        <div className={styles.recoveryDriveImg}>
                            <img src={Drive_Ilustration4_3} alt='illustration' />
                        </div>

                    </div>

                </div>
            </div>

            <div className={`${styles.drivePrivacyContainer} ${styles.driveContentContainer}`}>
                {/* <div className={styles.driveFeatures_header}>
                    Full Privacy for Files From Third-Party Apps on Your Device.
                </div> */}
                <div className={styles.simplifiedHeader}>
                    <div className={styles.driveFeatures_header}>
                        Full Privacy for Files From Third-Party Apps on Your Device.
                    </div>
                    {device === DEVICE.MOBILE &&
                        <div className={`${styles.driveFeatures_header} ${styles.driveFeature1Header}`}>Files are Invisible to All 3rd Party Apps with Storage Access on Device.</div>
                    }
                </div>
                <div className={styles.fullPrivacyImg_container}>
                    <img src={device == DEVICE.MOBILE ? `${Drive_Ilustration5_mobile}` : `${Drive_Ilustration5}`} alt='illustration' />
                </div>
            </div>

            <div className={`${styles.setUsApartContainer} ${styles.driveContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.driveFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.driveFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy.</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Keep Files Private, All the Time, Everywhere. Download Zunu Drive Now.</p>
                    {device !== DEVICE.MOBILE &&
                        <div className={styles.footerDownload_container}>

                            <div className={styles.footerBtn_container}>
                                <button className={styles.FooterDownloadBtn} id="banner2Download" onClick={() => { handleBuildDownload(os) }}>Download Now</button>
                            </div>

                            <div className={styles.footerLogoContainer}>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }} id="banner2Windows"><WindowsLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }} id="banner2Mac"><MacLogo className={styles.osLogos} /></Link>
                                {/* <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }} id="banner2Linux"><LinuxLogo className={styles.osLogos} /></Link> */}
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }} id="banner2AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>

                        </div>
                    }
                </div>
                <div className={styles.footerImgContainer}>
                    <img src={Macbook_desktop} alt='pixel mobile' />


                    {device == DEVICE.MOBILE &&
                        <div className={styles.footerLogoContainer}>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.WIN) }} id="banner2Windows"><WindowsLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.MAC) }} id="banner2Mac"><MacLogo className={styles.osLogos} /></Link>
                            {/* <Link to="" onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }} id="banner2Linux"><LinuxLogo className={styles.osLogos} /></Link> */}
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.IOS) }} id="banner2AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => { handleBuildDownload(PLATFORM.AND) }} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export { Home }